






















import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
    data() {
        return {
            currentTab: "",
            tabs: {
                business: {
                    label: "Kinh doanh xuất sắc",
                    condition() {
                        return userManager.checkRole(["kd", "gd",  "t"]);
                    },
                },
                notification: {
                    label: "Thông báo",
                },
                process: {
                    label: "Quy trình",
                },
                guider: {
                    label: "Hướng dẫn",
                },
                diagram: {
                    label: "Sơ đồ công ty",
                },
            },
        }
    },
    async created() {
        const pathItems = this.$route.path.split("/");
        this.tab = pathItems[4];
    },
});
